export const getPath = () => {
  if (!localStorage.api) {
    localStorage.setItem('api', 'ss');
  }
  return localStorage.api;
};

export const isRealApiData = () => {
  const path = getPath();
  return path === 'ss';
};

export const isRealApi = (ss?: boolean) => {
  return ss === undefined ? isRealApiData() : ss;
};
