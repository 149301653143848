import { DESCRIPTION_STRINGS } from '../../../common/index';

export const mapShelfFromMockToClient = (shelf) => {
  const { collaborationSummary, id, isConfidenceScoreSupported, localizations, type } = shelf;

  const baseShelf = {
    id,
    shelfName: type.displayName,
    shelfType: type.key,
    collaborationSummary: collaborationSummary || []
  };

  switch (type.key) {
    case DESCRIPTION_STRINGS.name:
    case DESCRIPTION_STRINGS.short:
    case DESCRIPTION_STRINGS.long:
      return {
        ...baseShelf,
        isConfidenceScoreSupported,
        localization: localizations[0]
      };
    default:
      return null;
  }
};

export const mapTaskFromMockToClient = (task) => {
  const {
    id,
    name,
    content,
    workflowId,
    locale,
    dueDate,
    taskStatus,
    priority,
    stateChangeDate,
    assignedVendor,
    assignee,
    externalRating,
    advanceOption,
    shelves,
    startDate,
    taskState,
    taskMode,
    copyDirection,
    isLocaleConfidenceScoreEnabled,
    mediaType,
    workflowType,
    workflowPlaylistType,
    workflowPlaylistSubtype,
    editorRejectionReasons
  } = task;

  return {
    id,
    name,
    adamId: content.id,
    workflowId,
    locale,
    dueDate,
    statusKey: taskStatus.key,
    priority,
    statusChangeDate: stateChangeDate,
    contentType: content.contentType,
    contentName: content.contentName,
    contentArtwork: content.contentArtwork,
    contentDescription: content.contentDescription,
    contentMetadataGlobalId: content.contentAssetUrl,
    vendor: assignedVendor,
    assignee,
    rating: externalRating.rating,
    advanceOptions: advanceOption,
    shelves: shelves ? shelves.map((shelf) => mapShelfFromMockToClient(shelf)) : [],
    expirationDate: startDate,
    mediaType,
    genres: content.genres,
    taskState,
    isLocaleConfidenceScoreEnabled,
    taskMode,
    copyDirection,
    workflowType: workflowType || null,
    workflowPlaylistType,
    workflowPlaylistSubtype,
    editorRejectionReasons
  };
};

export const mapSimpleTaskFromMockToClient = (task) => {
  const {
    id,
    content,
    locale,
    lastComment,
    dueDate,
    genres,
    taskStatus,
    assignee,
    priority,
    statusChangeDate,
    taskMode,
    taskState
  } = task;

  return {
    id: +id,
    name: content.contentName,
    adamId: content.id,
    locale,
    lastComment,
    dueDate,
    genres,
    statusKey: taskStatus.displayName,
    assigneeFirstName: assignee.firstName,
    assigneeLastName: assignee.lastName,
    priority,
    statusChangeDate,
    contentType: content.contentType,
    jobTypes: assignee.jobTypes,
    taskMode,
    taskState,
    assignee
  };
};

export const mapSimpleTasksFromMockToClient = (tasks) =>
  tasks.map((task) => mapSimpleTaskFromMockToClient(task));

export const mapVendorFromMockToClient = (vendor) => {
  const { id, name, activeEditorNumber, activeWriterNumber } = vendor;
  return {
    id,
    name,
    numberOfAuthors: activeWriterNumber,
    numberOfEditors: activeEditorNumber
  };
};

export const mapVendorsFromMockToClient = (vendors) =>
  vendors.map((vendor) => mapVendorFromMockToClient(vendor));

export const mapUserFromMockToClient = (user) => {
  const {
    identifier,
    firstName,
    lastName,
    displayName,
    email,
    jobTypes,
    vendor,
    locales,
    genres,
    contentTypes,
    mediaTypes,
    status,
    rating,
    completedTaskNumber,
    pendingTaskNumber,
    rejectedTaskNumber
  } = user;
  return {
    id: identifier,
    firstName,
    lastName,
    displayName,
    email,
    role: '',
    jobTypes,
    vendorName: vendor.name,
    locales,
    localeCodes: [''],
    favoriteGenre: genres,
    favoriteContentType: contentTypes,
    favoriteMedia: mediaTypes,
    available: status.key === 'Active',
    status,
    averageStarRating: rating,
    publishedTasks: completedTaskNumber,
    assignedTasks: pendingTaskNumber,
    rejectedTasks: rejectedTaskNumber
  };
};

export const mapUsersFromMockToClient = (users) =>
  users.map((user) => mapUserFromMockToClient(user));

export const mapConfidenceScoreFromMockToClient = (confidenceScore) => {
  const { results } = confidenceScore;
  const { status, data } = results[0];
  const { shelfLocalizationId, score, issues } = data.scoresAndIssues[0];
  return {
    status,
    scorePassed: true,
    shelfLocalizationId,
    score,
    issues
  };
};

export const mapMentionUsersFromMockToClient = (users) =>
  users.map((user) => ({
    id: user.userId.toString(),
    displayName: user.displayName
  }));
