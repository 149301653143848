import React, { FC, useEffect, useState } from 'react';

import { withModal } from '@providers/modal-provider';
import InfoModal from '@ui-components/info-modal';
import ReassignTableList from '@ui-components/reassign-table-list';
import UnassignTask from '@ui-components/unassign-task';
import { Column } from '@ui-components/table';
import { useDispatch, useSelector } from 'react-redux';

import {
  getWorklistSelectedIds,
  worklistSelectedTasks
} from '../../store/worklist/worklistSelector';
import { UserToReassign } from '../../common/types/UserToReassign';
import { updateBulkTaskRequest } from '../../store/task/taskActions';
import {
  clearReassignList,
  getReassignListRequest
} from '../../store/reassignment/reassignActions';
import reassignListSelector from '../../store/reassignment/reassignSelectors';
import { reassignConfigV2 } from './ReassignConfig';
import { TaskReassignActionTypes } from '../../store/reassignment/reassignTypes';
import createLoadingSelector from '../../store/loading/loadingSelector';

import { vendorProfile } from '../../api/worklist';
import { getV1NamespaceFromV2WorkflowIdentifier } from '@helper-hooks/general-utils';
import { WorkflowIdentifiersType } from '@providers/types-provider/src/WorkflowIdentifiersType';

interface Props {
  onClose: () => void;
}

const unassignUser = {
  vendor: null,
  name: '',
  email: '',
  userRealmIdentities: [
    {
      userRealm: '',
      id: '',
      fullyQualifiedId: ''
    }
  ],
  hasConfidentialAccess: false,
  isAppleInternal: false
} as UserToReassign;

const loadingReassignList = createLoadingSelector([TaskReassignActionTypes.GET_REASSIGN_LIST]);

export const ReassignTaskModal: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const reassignList = useSelector(reassignListSelector);
  const loading = useSelector(loadingReassignList);
  const selectedTaskIds = useSelector(getWorklistSelectedIds);
  const selectedTasks = useSelector(worklistSelectedTasks);

  const [isTaskUnassigned, setIsTaskUnassigned] = useState(false);
  const [reassignedUser, setReassignedUser] = useState<UserToReassign>(unassignUser);

  const onReassignChange = (newUser: UserToReassign) => {
    setReassignedUser(newUser);
    setIsTaskUnassigned(false);
  };

  const onUnassignChange = () => {
    setReassignedUser(unassignUser);
    setIsTaskUnassigned(true);
  };

  const onTaskReassign = () => {
    dispatch(
      updateBulkTaskRequest({ updateItems: createBulkUpdatePayload(), updateAttribute: 'reassign' })
    );
  };

  const mappedTaskState = () => {
    switch (selectedTasks[0]?.state) {
      case 'submit_copy':
        return 'writer';
      case 'external_review':
        return 'editor';
      case 'onsite_review':
      case 'adapt_internal_review':
        return 'reviewer';
      case 'review_copy':
        return 'reviewer';
      case 'internal_review':
        return 'reviewer';
      case 'geo_lead_review':
        return 'geo_lead';
      case 'vendor_review':
      case 'adapt_review':
      case 'adaptation_edit':
      case 'edit':
        return 'editor';
      case 'loc_producer_review':
        return 'loc_producer';
      case 'write':
        return 'writer';
      case 'review':
        return 'reviewer';
      default:
        return '';
    }
  };

  const selectedTasksLocales = () => {
    return selectedTasks.map((task: any) => task.locale);
  };

  const selectedTasksNamespace = () => {
    return selectedTasks[0]?.namespace;
  };

  const createBulkUpdatePayload = () => {
    return selectedTaskIds.map((taskId: string) => {
      return {
        namespace: selectedTasksNamespace(),
        taskId,
        command: {
          assignee: !isTaskUnassigned ? reassignedUser.email : null,
          assigneeName: !isTaskUnassigned ? reassignedUser.name : ''
        }
      };
    });
  };

  const isReassignSubmitDisabled = (!!reassignedUser.email || isTaskUnassigned) === false;

  useEffect(() => {
    const namespace = selectedTasksNamespace();
    const v1Namespace = getV1NamespaceFromV2WorkflowIdentifier(
      namespace as WorkflowIdentifiersType
    );
    // rdar://84201697 ([PROD] [P1] Shakespeare - Showstopper: Vendor Info Visible to Other Vendors)
    vendorProfile(v1Namespace).then((profile: any) => {
      dispatch(
        getReassignListRequest({
          namespace: namespace,
          vendor: profile.vendor,
          skill: {
            name: mappedTaskState(),
            locales: selectedTasksLocales()
          }
        })
      );
    });

    return () => {
      dispatch(clearReassignList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: This function is just temporary since sorting should be done on BE side - radar:75207376
  const onSortChange = (column: Column<UserToReassign>, direction: string) => {
    if (direction === 'asc')
      // @ts-ignore
      return reassignList.sort((a, b) =>
        // @ts-ignore
        a[column.dataIndex]?.toUpperCase() > b[column.dataIndex]?.toUpperCase() ? -1 : 1
      );
    if (direction === 'desc')
      // @ts-ignore
      return reassignList.sort((a, b) =>
        // @ts-ignore
        a[column.dataIndex]?.toUpperCase() < b[column.dataIndex]?.toUpperCase() ? -1 : 1
      );
  };

  return (
    <InfoModal
      isFullWidth
      title="Choose new assignee"
      closeModal={onClose}
      successButtonDisabled={isReassignSubmitDisabled}
      onSuccess={() => {
        onTaskReassign();
        onClose();
      }}
      successLabel="Choose"
    >
      <ReassignTableList
        loading={loading}
        reassignListData={reassignList}
        reassignedUserId={reassignedUser.email}
        onReassignChange={onReassignChange}
        columns={reassignConfigV2}
        onSortChange={onSortChange}
      />
      <UnassignTask isTaskUnassigned={isTaskUnassigned} onUnassignChange={onUnassignChange} />
    </InfoModal>
  );
};

export default withModal(ReassignTaskModal);
