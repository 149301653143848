import { Component, ReactNode } from 'react';
import { reportError } from '../../../utils/errorReportUtil';

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  // TODO research and consider adding it
  // static getDerivedStateFromError(error: Error) {
  //   // Maybe TODO possible to update state so the next render will show the fallback UI.
  //   reportError(error);
  // }

  componentDidCatch(error: Error, info: any) {
    reportError(error, { ...info, tags: { source: 'errorBoundary' } });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
