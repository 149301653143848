import axiosInstance from '../utils/axiosInstance';
import { getPromisesForApiCall } from '../utils/localStorageData';
import { getPath } from '../utils/AntiCorruption/antiCorruptionUtils';
import { getFilterSearchParams } from '../utils/worklistParams';
import { getContentNamespace } from '../utils/generalUtils';

import { getPreferences, putPreferences } from './userPreferences';
import {
  tasks,
  tasksV2Filters,
  availableTerms,
  reportTasks,
  exportTasks,
  importTasks,
  validateTasks,
  vendorProfile,
  filterSearchQueryParamsUrl
} from './worklist';
import BugReport from './BugReport';
import GlobalAlerts from './alerts';

const api = {
  BugReport,
  userPreferences: {
    getPreferences,
    putPreferences
  },
  worklist: {
    tasks,
    tasksV2Filters,
    availableTerms,
    reportTasks,
    exportTasks,
    importTasks,
    validateTasks,
    vendorProfile,
    filterSearchQueryParamsUrl
  },
  user: {
    login: (credentials) =>
      axiosInstance.post('/api/auth/login', { ...credentials }).then((res) => ({
        ...res.data.user,
        accessToken: res.data.accessToken
      })),
    fetchCurrentUser: () =>
      axiosInstance.get(`/api/api2/user/me`).then((res) => {
        return {
          ...res.data,
          accessToken: localStorage.appJWT
        };
      }),
    // TODO: Remove this if it is not used
    createUser: (userData) =>
      axiosInstance.post('/api/users', { ...userData }).then((res) => res.data),
    // TODO: Remove this if it is not used
    confirmUser: (data) => {
      const { confirmationToken, firstName, lastName, password } = data;
      return axiosInstance
        .patch(
          `/api/auth/registrationConfirmation?confirmationToken=${encodeURIComponent(
            confirmationToken
          )}`,
          {
            firstName,
            lastName,
            password
          }
        )
        .then((res) => ({
          ...res.data.user,
          accessToken: res.data.accessToken
        }));
    },
    fetchUsers: (queryParams) => {
      const urlPrefix = 'cap-mgt/worklist';
      let url = `/api/${urlPrefix}/availableTerms/assigneeName?${getFilterSearchParams(
        queryParams
      )}`;
      return axiosInstance.get(url).then((res) => res.data);
    },
    fetchUsersForReassign: (payload) => {
      return axiosInstance
        .post(`/${getPath()}/v1/users/query/assignee`, payload)
        .then((res) => ({ ...res.data }));
    },
    logoutUser: () => axiosInstance.post(`/api/auth/logout`).then((res) => res.data)
  },
  // TODO: Remove this if it is not used
  staticData: {
    getStaticData: () => {
      const promises = getPromisesForApiCall(axiosInstance);
      return Promise.all(promises).then((res) => res);
    }
  },
  tasks: {
    fetchAllQualifiedUsers: (payload) => {
      // this is deprecated, logic is moved to @helper-hooks/api qualifiedUsers method
      return axiosInstance
        .post(`api/fetchallqualifiedusers`, {
          ...payload
        })
        .then((res) => res.data);
    },
    resizeTable: () => {}
  },
  task: {
    // TODO: Is there a need to adapt this to use V2 api
    updateBulkTasks: (payload) => {
      const namespace = payload.length && payload[0].namespace;
      const contentNamespace = getContentNamespace(namespace);
      return axiosInstance.put(`api/${contentNamespace}/tasks/`, payload).then((res) => res);
    },
    retryBulkTasks: (payload) => {
      // Kick off Errored tasks again
      const namespace = payload.length && payload[0].namespace;
      const contentNamespace = getContentNamespace(namespace);
      return axiosInstance
        .post(`api/${contentNamespace}/tasks/advancement`, payload)
        .then((res) => res);
    }
  },
  alerts: {
    ...GlobalAlerts
  }
};
export default api;
