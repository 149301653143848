"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.api = void 0;
const util_1 = require("./util");
const axiosInstance_1 = require("./axiosInstance");
const confidenceScore = {
    check: ({ text, locale }) => {
        let run = ['html_tag', 'punctuation'];
        if (util_1.isConfidenceScoreSupportedByLocale(locale)) {
            run = ['spell', 'grammar', 'html_tag', 'punctuation'];
        }
        return axiosInstance_1.axiosInstance
            .post(`/api/confidence-score/v1/check`, {
            text,
            locale,
            run
        })
            .then((res) => res.data);
    },
    checkAll: (textFields, locale) => {
        const confidenceScoreCheckAll = textFields.map((text) => confidenceScore.check({ text, locale }));
        return Promise.all(confidenceScoreCheckAll).then((res) => res);
    }
};
const bugReport = {
    submit: (correlationKey, userAgent, user, pageUrl, context) => {
        return axiosInstance_1.axiosInstance
            .post(`/api/api2/report-a-bug`, {
            correlationKey,
            userAgent,
            user,
            pageUrl,
            context
        })
            .then((res) => res.data);
    }
};
const translationMemory = {
    search: (searchQuery) => {
        return axiosInstance_1.axiosInstance
            .post(`/api/translation-memory/v1/search`, searchQuery)
            .then((res) => res.data);
    }
};
exports.api = {
    taskHistory: {
        getTaskHistory: (workflowTypePath, workflowId) => axiosInstance_1.axiosInstance
            .get(`/api/${workflowTypePath}/history/workflows/${workflowId}`)
            .then((res) => res.data),
        putShelfHistory: (workflowTypePath, workflowId) => axiosInstance_1.axiosInstance
            .put(`/api/${workflowTypePath}/history/workflows/${workflowId}/textEdits`)
            .then((res) => res.data)
    },
    notes: {
        characterCountMappings: () => {
            return axiosInstance_1.axiosInstance
                .get(`/api/copy-service/notes/character-count-mappings`)
                .then((res) => res.data);
        },
        characterCount: (text) => {
            return axiosInstance_1.axiosInstance
                .post('/api/copy-service/notes/count-characters', {
                text
            })
                .then((res) => res.data);
        }
    },
    confidenceScore,
    translationMemory,
    bugReport,
    userProfile: (namespace) => {
        const namespaceUrl = namespace.split('.')[1];
        return axiosInstance_1.axiosInstance.get(`/api/${namespaceUrl}/user/profile`).then((res) => res.data);
    },
    qualifiedUsers: (payload) => {
        const { vendor, skill: { name, locales }, namespace } = payload;
        const urlPath = `api/cap-mgt/workspaces/${namespace}/qualifiedUsers`;
        const apiV2Payload = {
            metadata: {
                vendor: vendor ? [vendor] : []
            },
            skill: {
                name,
                requestedSkills: {
                    locales
                }
            }
        };
        return axiosInstance_1.axiosInstance.post(urlPath, apiV2Payload).then((res) => res.data);
    },
    qualifiedUsersV1: (payload) => {
        const urlPath = 'api/fetchallqualifiedusers';
        return axiosInstance_1.axiosInstance.post(urlPath, payload).then((res) => res.data);
    },
    changeState: (workflowTypePath, taskChangeState) => {
        const { url, comment, rating, issues, reviewDuration } = taskChangeState;
        return axiosInstance_1.axiosInstance
            .post(`api/${workflowTypePath}${url}`, {
            comment,
            rating,
            issues,
            reviewDuration
        })
            .then((res) => res.data);
    },
    dueDates: (workflowTypePath, payload) => {
        const { workflowId, calculationPayload } = payload;
        return axiosInstance_1.axiosInstance
            .post(`api/${workflowTypePath}/workflows/${workflowId}/dueDates`, Object.assign({}, calculationPayload))
            .then((res) => res.data);
    },
    collaboration: {
        batchSummary: (payload) => {
            return axiosInstance_1.axiosInstance
                .post(`api/collaboration/thread/batch/summary`, payload.threadIds)
                .then((res) => res.data);
        },
        getArtifactComments: (threadId) => {
            return axiosInstance_1.axiosInstance.get(`api/collaboration/thread/${threadId}`).then((res) => res.data);
        },
        getArtifactCommentsCount: (threadId) => {
            return axiosInstance_1.axiosInstance
                .get(`api/collaboration/thread/${threadId}/summary`)
                .then((res) => res.data);
        },
        addNewComment: (comment) => {
            const { threadId } = comment;
            return axiosInstance_1.axiosInstance
                .post(`api/collaboration/thread/${threadId}`, comment)
                .then((res) => res.data);
        },
        editComment: (comment) => {
            const { threadId, commentId, text, resourceURL, mentions } = comment;
            return axiosInstance_1.axiosInstance
                .put(`api/collaboration/thread/${threadId}/comment/${commentId}`, {
                text,
                resourceURL,
                mentions
            })
                .then((res) => res.data);
        },
        mention: (searchTerm, workflowTypePath) => {
            const workflowType = workflowTypePath ? workflowTypePath : 'copyworkflow';
            return axiosInstance_1.axiosInstance
                .get(`api/${workflowType}/user/search?text=${searchTerm}`)
                .then((res) => res.data);
        }
    }
};
