import { setError } from '@helper-hooks/error-pages-state-redux-action';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api/api';
import { ROUTES } from '../../common';
import i18n from '../../i18n';
import TRANSLATE_KEYS from '../../public/locales/translateKeys';
import { addMissingUserFields } from '../../utils/AntiCorruption/UserMappingLayer';
import { reportError } from '../../utils/errorReportUtil';
import history from '../../utils/history';
import { removeStaticDataFromLocalStorage } from '../../utils/localStorageData';
import setAuthToken from '../../utils/setAuthToken';
import UserPreferencesRedux, {
  userPreferencesUtils
} from '@helper-hooks/user-preferences-redux-action';
import {
  fetchCurrentUserFailure,
  fetchCurrentUserSuccess,
  userConfirmFailure,
  userConfirmSuccess,
  userLoggedOut,
  userLoginFailure,
  userLoginSuccess
} from './userActions';
import {
  CLEAR_USER_DATA,
  FETCH_USER_REQUEST,
  USER_CONFIRM_REQUEST,
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST
} from './userActionTypes';

export function* userLoginRequest(action: any) {
  try {
    setAuthToken(action.jwt);
    localStorage.appJWT = action.jwt;
    const user = yield call(api.user.fetchCurrentUser);
    yield put(userLoginSuccess(addMissingUserFields(user)));
    setAuthToken(action.jwt);
    localStorage.removeItem('musicAuthorizationWindowShown');

    // User Preferences
    const userPreferencesResponse = yield call(api.userPreferences.getPreferences, 'workflow', [
      'worklist',
      'betaFeatures',
      'settings',
      'filterPresets'
    ]);
    const preferences = userPreferencesUtils.getUserPreferencesData(userPreferencesResponse);
    yield put(UserPreferencesRedux.userPreferencesAction.getUserPreferencesSuccess(preferences));
    // with a specific URL so we can redirect them
    const initialPath = localStorage.getItem('initialPath');
    if (initialPath) {
      localStorage.removeItem('initialPath');
      // using window.location.replace
      // because it might be a path that's outside this app
      // and history.replace (client side router) won't work
      window.location.replace(initialPath);
      return;
    }
    // User didn't open the app before being authenticated
    history.replace(ROUTES.worklist);

    // const isDefaultFilterRejected = localStorage.getItem(
    //   "isDefaultFilterRejected"
    // );
    //
    // const hasAssigneeFilter = user?.displayPreferences?.tasks?.some(
    //   (filter: KeyDisplayName) => filter.key === "assignee"
    // );
    // // Check if user can have assignee filter at all, since some users might not have it.
    // if (!hasAssigneeFilter) return;
    //
    // // Here we need data if user has already selected some of the filters.
    // const filtersString = localStorage.getItem("filter");
    // const filters = filtersString && JSON.parse(filtersString);
    //
    // const multipleAssigneesFilter = filters?.tasks?.filter(
    //   (filter: FilterQueryParams) => {
    //     if (filter.field === "assignee") {
    //       return filter.value;
    //     }
    //     return filter.value;
    //   }
    // );
    //
    // let filter = {
    //   field: "assignee",
    //   operation: "",
    //   value: [{ value: user.id, text: user.name }]
    // };
    //
    // // Check for rejected default filter since it's needed only for the first time user is logging to the app
    // if (!isDefaultFilterRejected) {
    //   // Handling logic for filters in case there are more selected alongside or without default filter.
    //   if (multipleAssigneesFilter?.length) {
    //     yield put(setFilterTasks(multipleAssigneesFilter[0]));
    //   } else {
    //     // set only default filter on first login
    //     yield put(setFilterTasks(filter));
    //   }
    // }
  } catch (err) {
    yield put(userLoginFailure(err.response.data.message));
    history.push(`${ROUTES.login}`);
  }
}

export function* userFetchRequest() {
  try {
    setAuthToken(localStorage.appJWT);
    localStorage.removeItem('persistedFetchUserError');
    const user = yield call(api.user.fetchCurrentUser);
    yield put(fetchCurrentUserSuccess(addMissingUserFields(user)));

    // User Preferences
    const userPreferencesResponse = yield call(api.userPreferences.getPreferences, 'workflow', [
      'worklist',
      'betaFeatures',
      'settings',
      'filterPresets'
    ]);
    const preferences = userPreferencesUtils.getUserPreferencesData(userPreferencesResponse);
    yield put(UserPreferencesRedux.userPreferencesAction.getUserPreferencesSuccess(preferences));
  } catch (err) {
    const sagaNamespace = 'userFetchRequest';
    const correlationKey = err.response.config.headers['x-apple-jingle-correlation-key'];
    yield put(
      setError({
        errorCode: err.response.status,
        correlationKey,
        errorMessage: `${err.message} - ${sagaNamespace}`,
        componentStack: err.stack
      })
    );
    localStorage.removeItem('appJWT');

    // reason for this block is notifying user about a specific
    // bug when too large header size is sent from /me endpoint.
    // We need to persist this error message since redirect to login will clear up error store
    if (err.response.status === 400 || err.response.status === 431) {
      localStorage.persistedFetchUserError = i18n.t(
        TRANSLATE_KEYS.toast_messages.error.too_many_permissions
      );
      reportError(err, { tags: { errorInfo: 'header too large' } });
    } else {
      yield put(fetchCurrentUserFailure(err.response.data.message));
    }
    setAuthToken();
    yield put(userLoggedOut());
    history.push(`${ROUTES.login}`);
  }
}

export function* clearUserData() {
  localStorage.removeItem('appJWT');
  localStorage.removeItem('musicAuthorizationWindowShown');
  localStorage.removeItem('mk-player-tsid');
  localStorage.removeItem('api');
  setAuthToken();
  removeStaticDataFromLocalStorage();
  yield put(userLoggedOut());
  history.push(`${ROUTES.welcomePage}`);
}

export function* userLogoutRequest() {
  localStorage.removeItem('initialPath');
  yield call(api.user.logoutUser);
  yield call(clearUserData);
}

// TODO: Remove this if it is not used
export function* userConfirmRequest(action: any) {
  try {
    const user = yield call(api.user.confirmUser, action.data);
    localStorage.appJWT = user.accessToken;
    setAuthToken(user.accessToken);
    yield put(userConfirmSuccess(user));
    history.push(`${ROUTES.worklist}`);
  } catch (err) {
    yield put(userConfirmFailure(err.response.data.message));
    history.push(`${ROUTES.expirationPage}`);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(USER_LOGIN_REQUEST, userLoginRequest),
    takeLatest(FETCH_USER_REQUEST, userFetchRequest),
    takeLatest(CLEAR_USER_DATA, clearUserData),
    takeLatest(USER_LOGOUT_REQUEST, userLogoutRequest),
    takeLatest(USER_CONFIRM_REQUEST, userConfirmRequest)
  ]);
}
