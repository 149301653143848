import {
  DateRangeOperator,
  FilterConfigType,
  FilterInputEnum,
  FilterOperatorEnums
} from '@providers/types-provider';
import { DateRangePickerOperators, Step } from '@ui-components/table-filters';

export const NewTableFilterConfig: FilterConfigType[] = [
  {
    key: 'workflowType',
    displayName: 'Workflow Type',
    tooltip:
      'The vertical from which the task originated e.g. Music Content Workflow, Video Content Workflow, Copy Workflow, etc.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: [],
      optionRenderer: (text: string) => {
        if (text === 'COPY_WORKFLOW') {
          return 'Copy';
        } else if (text === 'workflowType') {
          return 'Workflow Type';
        } else {
          return text
            .toLowerCase()
            .split('_')
            .map((token: string) => token[0].toUpperCase() + token.substring(1))
            .join(' ');
        }
      }
    }
  },
  {
    key: 'assigneeName',
    displayName: 'Assignee',
    tooltip: 'A user or group currently assigned to the task.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'contentType',
    displayName: 'Content Type',
    tooltip:
      'For Music Content Workflow & Video Content Workflow only. Examples include: Movie, Album, Playlist, Collection etc.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'taskDri',
    displayName: 'Task DRI',
    tooltip: 'The user who created the task.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'locale',
    displayName: 'Locale',
    tooltip: 'The language and associated region from which this linguistic variant originates.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES_ANY, key: FilterOperatorEnums.INCLUDES_ANY },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'genres',
    displayName: 'Genre',
    tooltip: 'Music genres exclusive to Music Content Workflow.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES_ANY, key: FilterOperatorEnums.INCLUDES_ANY },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      options: [],
      defaultValue: []
    }
  },
  {
    key: 'taskStatus',
    displayName: 'Task Status',
    tooltip:
      'The current status of the task, examples include “Assigned”, “Exported”, “Imported”, “Completed”, etc.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: [],
      optionRenderer: (text: string) => {
        return text
          .split('_')
          .map((token: string) => {
            return token.charAt(0).toUpperCase() + token.substring(1).toLowerCase();
          })
          .join(' ');
      }
    }
  },
  {
    key: 'state',
    displayName: 'Task State',
    tooltip:
      'A workflow progresses through various states as it advances from one step to another. Gives more granularity than the step filter.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: [],
      optionRenderer: (text: string) => {
        return text
          .split('_')
          .map((token: string) => {
            return token.charAt(0).toUpperCase() + token.substring(1).toLowerCase();
          })
          .join(' ');
      }
    }
  },
  {
    key: 'dueDate',
    displayName: 'Due Date',
    tooltip: 'The date and time the task needs to be delivered, in this current step.',
    operator: {
      value: DateRangeOperator.ThisYear,
      type: FilterInputEnum.SELECT,
      options: DateRangePickerOperators
    },
    value: {
      type: FilterInputEnum.DATE,
      options: {
        gt: new Date(new Date().getFullYear(), 0, 1),
        lt: new Date(new Date().getFullYear() + 1, 0, 1)
      }
    }
  },
  {
    key: 'updatedAt',
    displayName: 'Updated At',
    tooltip:
      'The date and time any action was last taken on the task (any reassignment, edit, update etc).',
    operator: {
      value: DateRangeOperator.ThisYear,
      type: FilterInputEnum.SELECT,
      options: DateRangePickerOperators
    },
    value: {
      type: FilterInputEnum.DATE,
      options: {
        gt: new Date(new Date().getFullYear(), 0, 1),
        lt: new Date(new Date().getFullYear() + 1, 0, 1)
      }
    }
  },
  {
    key: 'statusUpdatedAt',
    displayName: 'Status Updated At',
    tooltip: 'The date and time the status was last updated.',
    operator: {
      value: DateRangeOperator.ThisYear,
      type: FilterInputEnum.SELECT,
      options: DateRangePickerOperators
    },
    value: {
      type: FilterInputEnum.DATE,
      options: {
        gt: new Date(new Date().getFullYear(), 0, 1),
        lt: new Date(new Date().getFullYear() + 1, 0, 1)
      }
    }
  },
  {
    key: 'createdAt',
    displayName: 'Created At',
    tooltip: 'The date and time the workflow was created.',
    operator: {
      value: DateRangeOperator.ThisYear,
      type: FilterInputEnum.SELECT,
      options: DateRangePickerOperators
    },
    value: {
      type: FilterInputEnum.DATE,
      options: {
        gt: new Date(new Date().getFullYear(), 0, 1),
        lt: new Date(new Date().getFullYear() + 1, 0, 1)
      }
    }
  },
  {
    key: 'confidential',
    displayName: 'Confidential',
    tooltip: 'Confidential tasks are accessible only to disclosed users.',
    operator: {
      value: FilterOperatorEnums.IS,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.SELECT,
      optionRenderer: (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    }
  },
  {
    key: 'priority',
    displayName: 'Priority',
    tooltip: 'Task created with priority status has a more aggressive due date.',
    operator: {
      value: FilterOperatorEnums.IS,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: 'True', key: 'true' },
        { displayName: 'False', key: 'false' }
      ]
    }
  },
  {
    key: 'adaptationFlow',
    displayName: 'Adaptation Flow',
    tooltip:
      'If the task for this locale is tied to a separate task from another locale for adaptation (e.g. de-CH adapted from de-DE).',
    operator: {
      value: FilterOperatorEnums.IS,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: 'True', key: 'true' },
        { displayName: 'False', key: 'false' }
      ]
    }
  },
  {
    key: 'title',
    displayName: 'Task Title',
    tooltip: 'The name of the task.',
    operator: {
      value: FilterOperatorEnums.IS,
      options: [
        {
          displayName: FilterOperatorEnums.IS,
          key: FilterOperatorEnums.IS
        },
        {
          displayName: FilterOperatorEnums.INCLUDES,
          key: FilterOperatorEnums.INCLUDES
        },
        {
          displayName: FilterOperatorEnums.NOT_CONTAINS,
          key: FilterOperatorEnums.NOT_CONTAINS
        }
      ],
      type: FilterInputEnum.SELECT
    },
    value: {
      type: FilterInputEnum.INPUT,
      defaultValue: ''
    }
  },
  {
    key: 'revision_round_integer',
    displayName: 'Revision',
    tooltip:
      'In Copy Workflow only. A task starts at revision 0, and increments +1 for each new revision round.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'backstage_task_id_text_general',
    displayName: 'ID',
    tooltip:
      'The ID used by the originating system. You can enter a Backstage task ID for Copy Workflow, or a content ID for Music Content Workflow or Video Content Workflow.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTIPLE_INPUT,
      defaultValue: []
    }
  },
  {
    key: 'classifier_text_general',
    displayName: 'Classifier',
    tooltip: 'In Copy Workflow only. Business attribute defined in Backstage.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        {
          displayName: FilterOperatorEnums.INCLUDES_ALL,
          key: FilterOperatorEnums.INCLUDES_ALL
        },
        {
          displayName: FilterOperatorEnums.INCLUDES,
          key: FilterOperatorEnums.INCLUDES
        }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'keyword_text_general',
    displayName: 'Keyword',
    tooltip:
      'In Copy Workflow only. Business attribute defined in Backstage referring to the product line.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'lob_text_full_match',
    displayName: 'LOB',
    tooltip:
      'In Copy Workflow only. Business attribute defined in Backstage referring to the line of business.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'project_text_general',
    displayName: 'Project',
    tooltip:
      'In Copy Workflow only. Business attribute defined in Backstage referring to the overarching project that the task stems from.',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'request_type_text_general',
    displayName: 'Request Type',
    tooltip:
      'In Copy Workflow only. Business attribute defined in Backstage referring to the type of work expected (Copywriting, Localization etc).',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'workflowId',
    displayName: 'Workflow ID',
    tooltip:
      'Individual identifier for a task in a given language. Remains the same ID throughout all steps.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      options: [
        {
          displayName: FilterOperatorEnums.INCLUDES_ANY,
          key: FilterOperatorEnums.INCLUDES_ANY
        },
        {
          displayName: FilterOperatorEnums.EXCLUDES,
          key: FilterOperatorEnums.EXCLUDES
        }
      ],
      type: FilterInputEnum.SELECT
    },
    value: {
      type: FilterInputEnum.MULTIPLE_INPUT,
      defaultValue: [],
      validator: new RegExp(/.{8}-.{4}-.{4}-.{4}-.{12}/)
    }
  },
  {
    key: 'taskId',
    displayName: 'Task ID',
    tooltip:
      'Refers to the ID of the workflow in a given step: there is one task ID for the writer step, another ID for the editor step etc.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      options: [
        {
          displayName: FilterOperatorEnums.INCLUDES_ANY,
          key: FilterOperatorEnums.INCLUDES_ANY
        },
        {
          displayName: FilterOperatorEnums.EXCLUDES,
          key: FilterOperatorEnums.EXCLUDES
        }
      ],
      type: FilterInputEnum.SELECT
    },
    value: {
      type: FilterInputEnum.MULTIPLE_INPUT,
      defaultValue: []
    }
  },
  {
    key: 'iso3aCountryCodes',
    displayName: 'Storefront',
    tooltip:
      'For Music Content Workflow & Video Content Workflow only. Filter on tasks for a given storefront.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      options: [
        {
          displayName: FilterOperatorEnums.INCLUDES_ANY,
          key: FilterOperatorEnums.INCLUDES_ANY
        },
        {
          displayName: FilterOperatorEnums.EXCLUDES,
          key: FilterOperatorEnums.EXCLUDES
        }
      ],
      type: FilterInputEnum.SELECT
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'isRead_bool',
    displayName: 'Read Status',
    tooltip: 'Filter by read or unread tasks.',
    operator: {
      value: FilterOperatorEnums.IS,
      type: FilterInputEnum.VALUE
    },
    value: {
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: 'True', key: 'true' },
        { displayName: 'False', key: 'false' }
      ]
    }
  },
  {
    key: 'placementTypes_text_full_match',
    displayName: 'Placement Type',
    tooltip: 'For Video Content Workflow only. Refers to the type of widget the copy will live in.',
    operator: {
      value: FilterOperatorEnums.INCLUDES_ANY,
      options: [
        {
          displayName: FilterOperatorEnums.INCLUDES_ANY,
          key: FilterOperatorEnums.INCLUDES_ANY
        },
        {
          displayName: FilterOperatorEnums.EXCLUDES,
          key: FilterOperatorEnums.EXCLUDES
        }
      ],
      type: FilterInputEnum.SELECT
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      defaultValue: []
    }
  },
  {
    key: 'step',
    displayName: 'Step',
    tooltip: 'To filter on tasks in a given step of the workflow (writer, editor, reviewer etc).',
    operator: {
      value: FilterOperatorEnums.INCLUDES,
      type: FilterInputEnum.SELECT,
      options: [
        { displayName: FilterOperatorEnums.INCLUDES, key: FilterOperatorEnums.INCLUDES },
        { displayName: FilterOperatorEnums.EXCLUDES, key: FilterOperatorEnums.EXCLUDES }
      ]
    },
    value: {
      type: FilterInputEnum.MULTI_VALUE_SELECT,
      options: [
        { displayName: 'Writer', key: Step.WRITER },
        { displayName: 'Editor', key: Step.EDITOR },
        { displayName: 'Reviewer', key: Step.REVIEWER },
        { displayName: 'Language Manager', key: Step.LANGUAGE_MANAGER },
        { displayName: 'Producer', key: Step.PRODUCER },
        { displayName: 'Business', key: Step.BUSINESS }
      ],
      defaultValue: []
    }
  }
];
